<template>
  <div class="ShippingPage">
    <div class="ShippingPage__description">
      <h1>Shipping & Delivery (UK Only)</h1>
      <h3>Shipping rates & delivery options at {{ siteConstants.siteName }} marketplace:</h3>
      <h4>Orders from the
        <router-link to="/o/vegan-kind">
          <span style="text-decoration: underline;">The Good Grocer</span>
        </router-link>
        (merchant)
      </h4>
      Shipping options:<br />
      Orders from £60 - free delivery (including chilled and frozen)<br />
      Orders less than £60 - shipping cost £8.90 (including chilled and frozen)<br />
      <br />
      <h4>Orders from
        <router-link to="/o/happy-beans">
          <span style="text-decoration: underline;">Happy Beans</span>
        </router-link>
        (merchant)
      </h4>
      Shipping options: <br />
      Orders from £60 - free NEXT DAY delivery (including chilled and frozen)<br />
      Orders less than £60 - shipping cost £8.90 (including chilled and frozen)<br />
      <br />
      <h4>Orders from other merchants and directly from brands</h4>
      Please, check the shipping cost in your cart.

      <br />
      <br />
      <br />
      Once, you complete your order, you will receive notifications about shipping and delivery and you can chill out while waiting for your goodies.
      Our goal is to offer you the Next Day Delivery service but currently we cannot promise it (and we don’t make promises that we can’t keep).
      Please, check our delivery slots (do not confuse with sloths 🦥 😉)  Well, we know it’s not the fastest service yet, but we are working on improving it.
      We appreciate your understanding. ❤️<br />
      The NEXT DAY delivery is available for items purchased from our merchant <router-link to="/o/happy-beans"><span style="text-decoration: underline">Happy Beans</span></router-link>.

      <table style="margin-top: 40px; width: 80%">
        <caption style="text-align: left;"><b>Weekly Delivery Plan for {{ siteConstants.siteName }} Supermarket</b></caption>
        <tr style="height: 15px"/>
        <tr>
          <th>Order Days</th>
          <th>Delivered To You</th>
        </tr>
        <tr>
          <td>Friday / Saturday / Sunday</td>
          <td>Wednesday</td>
        </tr>
        <tr>
          <td>Monday</td>
          <td>Thursday</td>
        </tr>
        <tr>
          <td>Tuesday</td>
          <td>Friday</td>
        </tr>
        <tr>
          <td>Wednesday</td>
          <td>Saturday</td>
        </tr>
        <tr>
          <td>Thursday / Friday</td>
          <td>Tuesday</td>
        </tr>
      </table>
      <br />
      <h3>Before you complete the order:</h3>
      <ul>
        <li>Please, ensure you are happy with the items in your Shopping Cart/Basket before making payment and completing your order.</li>
        <li>Please, make sure all the information and the delivery address are correct before completing your order.</li>
        <li>Please, check your Order Confirmation email to review the details of the order that you have submitted.</li>
        <li>Please, check your delivery date and ensure the parcel can be safely picked up at your place. We recommend transferring frozen goods to a freezer immediately upon delivery.</li>
      </ul>
      <h3>Packaging & delivery</h3>
      We pack our chilled and frozen items in recyclable cardboard boxes containing a sealed ice packs.
      The ice packs can be reused. To keep our frozen and chilled items fully insulated, we line the box with
      insulated pouches which are made with recycled denim that otherwise would have been sent to landfill.
      <br />
      <br />
      Our main delivery partner is DPD. As our chilled and frozen products are perishable items,
      they are unable to be delivered through our usual courier service outside the mainland UK.
      <br />
      <br />
      Once your parcel has been dispatched from our warehouse, you will receive a text and/or email
      notifying you that your order is on its way and it will be delivered the next day.
      The next morning you will receive a text and/or email notification informing you of tracking information.
      <br/>
      <br/>
      Our courier will provide you with tracking details and a time slot so you can follow your delivery or
      provide extra information if you are not going to be available to accept the delivery.
      You will receive a text and/or email with tracking information on the morning of your delivery.
      <br />
      <br />
      You do not need to be home, but please let us know where to leave the delivery when placing your order.
      If you cannot be at home during the delivery window provided, you can inform the courier where to leave your order safely.
      <br/>
      <br/>
      All orders are left at the buyer’s risk. The frozen items are packed in a way to ensure it remains frozen in transit.
      Please transfer the frozen items immediately to a freezer upon delivery. Please note, we cannot provide any refunds
      for orders which are not immediately transferred to the freezer to prevent defrosting.
      <h3>Returns & Refunds</h3>
      In the unlikely event that goods have not been shipped in accordance with the purchase order,
      please contact our customer service right away to agree on a refund of the missing item.
      We can only provide replacement for the branded items from Wholefood Earth.
      <br />
      <br />
      If our products arrived damaged in any way, please contact us right away to agree a refund for a damaged item.
      In order to get a full refund, you have to return the items you have purchased to us within 7 calendar days of the order.
      <br />
      <br />
      We accept the returns of all non-perishable foods (non-chilled and non-frozen) packaged foods and drinks,
      in their unopened original packaging. In order to receive a refund, all items should be shipped back to us.
      Please note, we cannot accept returns of frozen foods. We don’t provide any refunds for orders which are not
      immediately transferred to the freezer to prevent defrosting.
    </div>
  </div>
</template>

<script>
// import VueMarkdown from 'vue-markdown'
import siteConstants from '@/const.js'

export default {
  name: 'ShippingPage',
  components: {
    // VueMarkdown
  },
  data () {
    return {
      siteConstants
    }
  }
}
</script>

<style scoped lang="stylus">
.disabled
  opacity 0.1

.ShippingPage
  display flex
  background white
  align-items flex-start
  overflow-y auto
  justify-content center
  height 100%
  flex-wrap wrap
  padding 50px 0
  box-sizing border-box
  &__icon
    margin-top 70px
    font-size 140px
    display inline-block
  &__description
    text-align left
    line-height 1.6
    max-width 650px
    display inline-block
    box-sizing border-box
    font-size 14px
    /deep/ h1
      font-size 27px
      font-weight 300
      color black
    h1
     font-family: 'Bebas Neue', sans-serif;
    h2, h3
      font-family: Helvetica
      margin 30px 0 10px 0

@media screen and (max-width: 868px)
  .ShippingPage
    padding 30px 10px
</style>
